import { format, parseISO, subMinutes } from 'date-fns';
import {
  utcToZonedTime,
} from 'date-fns-tz';

const formatDateTime = (datetimeVal, selectedFormat) => {
  try {
    if (!datetimeVal) {
      return '';
    }

    if (typeof datetimeVal === 'string') {
      return format(parseISO(datetimeVal), selectedFormat);
    }

    return format(datetimeVal, selectedFormat);
  } catch (error) {
    return '';
  }
};

export const formatInZonedTime = (datetimeVal, selectedFormat, timezone) => {
  if (!timezone) {
    return formatDateTime(datetimeVal, selectedFormat);
  }

  try {
    if (!datetimeVal) {
      return '';
    }

    const zonedDate = utcToZonedTime(datetimeVal, timezone);

    const subtractedZonedDate = subMinutes(zonedDate, zonedDate.getTimezoneOffset());

    return format(subtractedZonedDate, selectedFormat);
  } catch (error) {
    return '';
  }
};

const getLocalISOTime = (dateTimeVal) => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000;
  const localISOTime = (new Date(dateTimeVal - tzoffset)).toISOString().slice(0, -1);
  return localISOTime;
};

const getPrevMonthDate = () => {
  const curentDate = new Date();
  curentDate.setDate(curentDate.getDate() - 30);
  return getLocalISOTime(curentDate);
};

const getNextMonthDate = (date) => {
  const dateObj = new Date(date);
  const newToDate = dateObj?.setDate(dateObj?.getDate() + 30);
  return getLocalISOTime(newToDate);
};

export {
  // formatInZonedTime,
  getLocalISOTime,
  getPrevMonthDate,
  formatDateTime,
  getNextMonthDate
};
