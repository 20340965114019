import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      Search: 'Search',
      All: 'All',
      News: 'News',
      Overview: 'Overview',
      Header_RegionSearch: 'Region Search',
      Header_RegionManagement: 'Region Management',
      Header_AddRegion: 'Add Region',
      Header_EditRegion: 'Edit Region',
      Header_OrderProgress: 'Order Progress',
      Header_Dashboard: 'Dashboard',

      Breadcrumb_RegionManagement: 'Region Management',

      Breadcrumb_Home: 'Home',

      Mask_CNPJ: '99.999.999/9999-99',
      Mask_CPF: '999.999.999-99',

      Text_AddRegion: 'Add Region',
      Text_EditRegion: 'Edit Region',
      Text_RegionID: 'Region ID',
      Text_RegionName: 'Region Name',
      Text_Region: 'Region',
      Text_SourceSystem: 'Source System',
      Text_Active: 'Active',
      Text_IMONo: 'IMO Number',
      Table_NoRegionfound: 'No Region Found!',
      Table_NoOrdersFound: 'No Orders Found',

      Text_DateFrom: 'Date From',
      Text_DateTo: 'Date To',
      Text_RefNo: 'Ref No',
      Text_AWBNO: 'AWB No',
      Text_Status: 'Status',

      Text_Client: 'Client',
      Text_Reference: 'Reference',

      Text_RequestedDateTo: 'Date To',
      Text_RequestedDateFrom: 'Date From',
      Text_PendingPickup: 'Pending Pickup',
      Text_Delivered: 'Delivered',
      Text_InTransit: 'In Transit',

      Text_From: 'From',
      Text_To: 'To',
      Text_IMONumber: 'IMO Number',
      Text_ReferenceNo: 'Reference No',
      Text_AWBNo: 'AWB No',

      Label_FromAToZ: 'From A to Z',
      Label_FromZToA: 'From Z to A',
      Label_RegionName: 'Region Name',
      Label_ClientName: 'Client Name',
      Label_IMO: 'IMO Number',

      Label_TrackYourOrder: 'Track Your Order',

      Label_Warning: 'Warning',
      Label_Close: 'Close',

      Label_YourDetails: 'Sender Details',
      Label_Address: 'Sender/Collection Address',
      Label_CreateYourLogin: 'Create Your Login',
      Label_AcceptTerms: 'Accept Terms',

      Label_RegistrationSuccessful: 'Registration Successful',

      Label_SenderDetails: 'Sender Details',
      Label_SenderAddress: 'Sender/Collection Address',
      Label_ParcelDetails: 'Parcel Details',
      Label_PickupDateTimePort: 'Pick Up Date/Time & Port',

      Label_SenderName: 'Sender Name',
      Label_CompanyName: 'Company Name',
      Label_Phone: 'Phone',
      Label_Email: 'Email',
      Label_Timezone: 'Timezone',

      Label_FirstLine: 'Address Line 1',
      Label_SecondLine: 'Address Line 2',
      Label_ThirdLine: 'Address Line 3',
      Label_TownCity: 'Suburb',
      Label_State: 'State',
      Label_PostCode: 'Post Code',
      Label_City: 'City',
      Label_Country: 'Country',

      Label_EarliestDateTime: 'Earliest Pickup Date/Time',
      Label_LatestDateTime: 'Latest Pickup Date/Time',
      Label_BunkerPort: 'Bunker Port',

      Label_Type: 'Type',
      Label_NumberOfParcel: 'No Of Parcels',
      Label_IMONumber: 'IMO Number',
      Label_VesselName: 'Vessel Name',

      Label_Fuel: 'Fuel',
      Label_Lube: 'Lube',
      Label_Water: 'Water',

      Label_Change: 'Change',

      Label_VerifuelLab: 'Verifuel Lab',

      Label_SelectPhoneNumber: 'Select Phone Number',
      Label_SelectEmail: 'Select Email',
      Label_SelectAddress: 'Select Address',
      Label_Optional: 'Optional',

      Label_Documentation: 'Documentation',
      Label_CollectionReference: 'Collection Reference',
      Label_Tracking: 'Tracking',

      Label_Sort: 'Sort',
      Label_Direction: 'Direction',
      Label_Filters: 'Filters',
      Label_From: 'From',
      Label_To: 'To',
      Label_ReferenceNo: 'Enter Reference No',
      Label_AWBNo: 'Enter AWB No',
      Label_Status: 'Select Status',
      Label_statuses: 'Select Status',

      Label_OrderHistory: 'Order History',
      Label_Add: 'Add',
      Label_AddOrder: 'Add Order',
      Label_AddressLine1: 'Address Line 1',
      Label_AddressLine2: 'Address Line 2',
      Label_AddressLine3: 'Address Line 3',
      Label_NewPassword: 'New Password',
      Label_ConfirmNewPassword: 'Confirm New Password',
      Label_ForgotPassword: 'Forgot Password',
      Label_referenceNo: 'Reference No',
      Label_status: 'Status',
      Label_awbNo: 'AWB No',
      Label_from: 'Date From',
      Label_to: 'Date To',
      Label_RegistrationType: 'RegistrationType',
      Label_CNPJ: 'CNPJ',
      Label_CPF: 'CPF',
      'Label_Aadhaar Number': 'Aadhaar Number',
      Label_GSTIN: 'GSTIN',
      'Label_Unique Identification Number': 'Unique Identification Number',
      'Label_Passport Number': 'Passport Number',
      'Label_PAN Card': 'PAN CARD',
      'Label_IE/RG': 'IE/RG',
      Label_VAT: 'VAT',

      Button_Save: 'Save',
      Button_SaveFilter: 'Save Filter',
      Button_SavedFilter: 'Saved Filter',
      Btn_ShowFilter: 'Show Filter',
      Btn_HideFilter: 'Hide Filter',
      Button_Back: 'Back',
      Button_Delete: 'Delete',
      Btn_Apply: 'Apply',
      Btn_Reset: 'Reset',
      Btn_ClearFilters: 'Clear Filters',
      Btn_ManageDisplay: 'Manage Display',
      Btn_StopImpersonating: 'Stop Impersonating',
      Btn_Logout: 'Log Out',
      Btn_Add: 'Add',
      Btn_Cancel: 'Cancel',
      Btn_Yes: 'Yes',
      Btn_No: 'No',
      Btn_Upload: 'Upload',
      Btn_NewDocument: 'Document',
      Btn_Submit: 'Submit',
      Btn_Settings: 'Settings',
      Button_Update: 'Update',
      Btn_AddNew: 'Add New',
      Btn_Close: 'Close',
      Btn_Register: 'Register',
      Btn_Track: 'Track',
      Btn_CopyOrder: 'Copy Order',

      Btn_Review: 'Review',
      Btn_Remove: 'Remove',
      Btn_Delete: 'Delete',
      Btn_Complete: 'Confirm',
      Btn_Save: 'Save',

      Btn_OrderDetails: 'Order Details',

      Btn_ChangeMyPassword: 'Change My Password',

      Tooltip_RegionManagement: 'Sorby Portal | Region Management',
      Tooltip_AddRegion: 'Sorby Portal | Add Region',
      Tooltip_EditRegion: 'Sorby Portal | Edit Region',

      Tooltip_Remove: 'Remove',
      Tooltip_AddMore: 'Add More',
      Tooltip_Dashboard: 'Dashboard',

      Error_RegionIsRequired: 'Region is Required',
      Error_ReferenceNumberIsRequired: 'Reference Number Is Required',
      Error_AWBNumberIsRequired: 'AWB Number Is Required',
      Error_TimezoneRequired: 'Timezone is Required',
      Error_ImoNumberValid: 'IMO number is valid',
      Error_ImoNumberInvalid:
        'No Match Found! Please make sure the Correct IMO Number is entered from IATA Carton',
      Error_LoadingSuccessfull: 'Data loaded successfully',
      Error_LoadingError: 'Loading Error',
      Error_LoadingDataError: 'Loading Error',

      Toast_UpdatedSuccessfully: 'Updated Successfully',
      Toast_FilterDisplayUpdatedSuccessfully: 'Filter display updated successfully',
      Error_Message_ChangePasswordError: 'Change Password failed',
    },
  },
  fr: {
    translation: {
      Search: 'Rechercher',
    },
  },
  es: {
    translation: {
      Search: 'Búsqueda',
    },
  },
  cn: {
    translation: {
      Search: '搜索',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
