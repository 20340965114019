export const AllRegistrationTypes = [
  'RegistrationType',
  'CNPJ',
  'CPF',
  'Aadhaar Number',
  'GSTIN',
  'Unique Identification Number',
  'Passport Number',
  'PAN CARD',
  'IE/RG',
  'VAT'];
