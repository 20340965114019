import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';
import { getNextMonthDate, getPrevMonthDate } from '../../../../utils/dateTime';

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;

  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }
  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  [
    'from',
    'to',
    'referenceNo',
    'awbNO',
    'IMO',
    'VesselName',
    'ClientName'
  ].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });

  state.filter['statuses'] = fieldObj['statuses'] ?? [];
};

const initialFilterState = {
  from: getPrevMonthDate(),
  to: new Date().toISOString(),
  referenceNo: '',
  awbNo: '',
  status: null,
  IMO: '',
  VesselName: '',
  ClientName: '',

  statuses: [],
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'EarliestPickupOn',
  sortDirection: SortDirection.Desc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,
  orders: [],
  totalOrderCount: 0,
  orderFilterData: [],
  totalFilterCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed
  page: 1,
  sort: null,
  isFilterVisible: false,
  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,
  isSavedFilterLoaded: false,
  savedFilterList: [],
  loadingFilterSettingtatus: LoadingStatus.Idle,
  onTableMode: false,
  statusCountLoading: false,
  allDacketStatus: [],
  currentStatus: ''
};
export const sliceName = 'dashboard';
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      if (action.payload.propName === 'from') {
        const fromDate = action.payload.propValue;
        state.filter['to'] = getNextMonthDate(fromDate);
      }
      state.filter[action.payload.propName] = action.payload.propValue;
    },
    dashboardFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFilter(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      console.log(action);
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchOrder(state, action) {
      state.orders = action.payload.orderTracking;
      state.totalOrderCount = action.payload.totalOrderCount;
    },

    orderFilter(state, action) {
      state.orderFilterData = action.payload.ordersFilter;
      state.totalFilterCount = action.payload.totalFilterCount;
    },

    updateFilterShowHideSettings(state, action) {
      state.orderFilterData = action.payload;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      console.log(action);
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },

    updateLoadingFilterSettingStatus(state, action) {
      state.loadingFilterSettingtatus = action.payload;
    },
    updateSearchResult(state, action) {
      console.log('here dash', action);
      const { orders, totalOrderCount } = action.payload;

      state.orders = orders;
      state.totalOrderCount = totalOrderCount;
    },
    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },
    updateTableMode(state, action) {
      state.onTableMode = action.payload;
    },
    updateStatusCountLoading(state, action) {
      state.statusCountLoading = action.payload;
    },
    updateAllDocketStatus(state, action) {
      state.allDacketStatus = [...action.payload];
    },
    updateCurrentStatus(state, action) {
      state.currentStatus = action.payloadl;
    }
  },
});

export const filterFields = {
  from: 'from',
  to: 'to',
  referenceNo: 'referenceNo',
  awbNo: 'awbNo',
  status: 'statuses',
  IMO: 'IMO',
  VesselName: 'VesselName',
  ClientName: 'ClientName',
};

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
