import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../utils/constants';

const initialState = {

  clients: [],
  loadingClientStatus: LoadingStatus.Idle, // idle, pending, success, failed
  objectConfigsById: {},
  // idle, pending, success, failed

  jobStatuses: [],
  loadingJobStatusStatus: LoadingStatus.Idle, // idle, pending, success, failed,

  locations: [],
  loadinglocationsStatus: LoadingStatus.Idle, // idle, pending, success, failed

  documentTypeList: [],
  loadingDocumentTypeStatus: LoadingStatus.Idle, // idle, pending, success, failed

  zoneList: [],
  loadingZoneStatus: LoadingStatus.Idle, // idle, pending, success, failed
};

const globalDataSlice = createSlice({
  name: 'globalData',
  initialState,
  reducers: {
    updateLoadingStatus(state, action) {
      state.loadingClientStatus = action.payload;
    },
    updateClients(state, action) {
      state.clients = action.payload;
    },

    updateJobStatuses(state, action) {
      state.jobStatuses = action.payload;
    },
    updateJobLoadingStatus(state, action) {
      state.loadingJobStatusStatus = action.payload;
    },

    updateLocations(state, action) {
      state.locations = action.payload;
    },
    updateLocationsStatus(state, action) {
      state.loadinglocationsStatus = action.payload;
    },

    updateDocumentTypeList(state, action) {
      const { items, status } = action.payload;
      if (items) {
        state.documentTypeList = items;
      }

      state.loadingDocumentTypeStatus = status;
    },

    updateZoneList(state, action) {
      const { items, status } = action.payload;
      if (items) {
        state.zoneList = items;
      }

      state.loadingZoneStatus = status;
    },
    updateObjectConifgs(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach(({ id, ObjectConfigs }) => {
          state.objectConfigsById[id] = ObjectConfigs;
        });
      }
    },
  },
});

export const globalDataActions = globalDataSlice.actions;
export default globalDataSlice.reducer;
